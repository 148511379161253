import {gql, useMutation, useQuery} from '@apollo/client'
import {navigate} from '@reach/router'
import {Link} from 'gatsby'
import React, {useState} from 'react'
import {PlusCircleIconSmall, Skeleton} from '../components'
import Page from '../components/page'
import {
  Table,
  PageHeading,
  Button,
  ConfirmDialog,
  Banner,
  Modal,
  SectionHeading,
  ModalProps,
  FormInput,
} from '../components/tailwind'
import {LocationAddLocationMutation} from '../graphql/LocationAddLocationMutation'
import {LocationsGetLocationsQuery} from '../graphql/LocationsGetLocationsQuery'
import {Location} from '../models'

export const GET_LOCATIONS = gql`
  query LocationsGetLocationsQuery {
    locations {
      id
      name
      address

      rooms {
        id
        name
      }
    }
  }
`

export const DELETE_LOCATION = gql`
  mutation LocationDeleteLocationMutation($id: ID!) {
    deleteLocation(id: $id)
  }
`

export const ADD_LOCATION = gql`
  mutation LocationAddLocationMutation($name: String!) {
    addLocation(name: $name) {
      id
      name
    }
  }
`

const AddLocationDialog: React.FC<ModalProps> = ({onDismiss}) => {
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string>()
  const [addLocation, {loading: adding, error}] = useMutation<LocationAddLocationMutation>(ADD_LOCATION)

  const handleSave = async (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault()

    setNameError(undefined)

    if (!name) {
      setNameError('Name is required')
      return
    }

    const {
      data: {
        addLocation: {id},
      },
    } = await addLocation({variables: {name}})
    onDismiss()
    navigate(`/locations/${id}`)
  }

  const handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  return (
    <Modal onDismiss={onDismiss}>
      <SectionHeading title="Add Location" />
      <form autoComplete="off" className="mt-3" onSubmit={handleSave}>
        <FormInput
          required
          autoFocus
          label="Location Name"
          value={name}
          error={nameError}
          onChange={handleNameChanged}
        />
        <div className="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button fullWidth className="sm:col-start-2" busy={adding} type="submit" onClick={handleSave}>
            Save
          </Button>
          <Button fullWidth secondary className="mt-3 sm:mt-0 sm:col-start-1" onClick={onDismiss}>
            Cancel
          </Button>
        </div>

        {error && <Banner type="error" content={error.message} className="mt-3" />}
      </form>
    </Modal>
  )
}

const LocationsPage: React.FC = () => {
  const {data, loading, refetch} = useQuery<LocationsGetLocationsQuery>(GET_LOCATIONS)
  const {locations} = data ?? {}
  const [deleteLocationMutation] = useMutation(DELETE_LOCATION)
  const [showAddLocation, setShowAddLocation] = useState(false)
  const [deleteLocation, setDeleteLocation] = useState<Location>()

  const handleDeleteLocation = (location: Location) => {
    setDeleteLocation(location)
  }

  const handleDeleteConfirmation = async (result: boolean) => {
    setDeleteLocation(undefined)

    if (result) {
      await deleteLocationMutation({variables: {id: deleteLocation.id}})
      refetch()
    }
  }

  const handleAddLocation = () => {
    setShowAddLocation(true)
  }

  const handleAddLocationDismissed = () => {
    setShowAddLocation(false)
    refetch()
  }

  if (loading)
    return (
      <Page title="Team">
        <Skeleton />
      </Page>
    )

  return (
    <Page title="Locations">
      <PageHeading title="Locations">
        <Button size="small" icon={PlusCircleIconSmall} className="mt-3 md:mt-0" onClick={handleAddLocation}>
          Add Location
        </Button>
      </PageHeading>

      {locations && (
        <Table
          items={locations}
          columns={[
            {name: 'Name', value: (l) => l.name},
            {name: '# of Rooms', value: (l) => l.rooms.length},
            {
              value: (l) => (
                <div className="flex justify-end space-x-2 whitespace-nowrap text-right text-sm leading-5">
                  <Link to={l.id} className="font-medium text-indigo-600 hover:text-indigo-900">
                    Edit
                  </Link>
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer"
                    onClick={() => handleDeleteLocation(l)}
                  >
                    Delete
                  </a>
                </div>
              ),
            },
          ]}
        />
      )}

      {deleteLocation && (
        <ConfirmDialog title={`Confirm Delete: ${deleteLocation.name}`} onDismiss={handleDeleteConfirmation}>
          <h1 className="text-gray-700">Are you sure you want to delete this location?</h1>
          <Banner type="error" content="This action cannot be undone" />
        </ConfirmDialog>
      )}

      {showAddLocation && <AddLocationDialog onDismiss={handleAddLocationDismissed} />}
    </Page>
  )
}

export default LocationsPage
